
import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import homebanner from '../../images/landing/homebannerbg.jpg';
import './faq_styles.scss'
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
}));

export default function Faq() {
    const docs = useFirestore('fl_content', 'faq');
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(null);
    const [temp] = React.useState([1, 2, 3])
    const handleExpandClick = (index) => {
        if (expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index);
        }
    };

    return (
        <Layout>
            <SEO title="FAQ" description="" />
            <section className="faq_banner">
                <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                <Container>
                    <div className="bannerCont">
                        <Grid container>
                            <Grid item xs="12" sm={6} >
                                <h2>FAQ<span>s</span></h2>
                            </Grid>
                            <Grid item xs="12" sm={6}></Grid>
                        </Grid>
                    </div>
                </Container>
            </section>

            <Container>
                {!_.isEmpty(docs) ? docs[0].questions.map((faq, index) => (
                    <Card className={classes.root}>
                        <CardHeader
                            avatar={
                                <div>
                                    <div className="RoundedRectangle16">
                                        <div className="plus">+</div>
                                    </div>
                                </div>
                            }
                            title={faq.quesTitle}
                            onClick={() => { handleExpandClick(index) }}
                        />
                        <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {
                                        <div className="justify-content-between blog-main-text" dangerouslySetInnerHTML={{ __html: faq.answers }}></div>
                                    }
                                </Typography>
                            </CardContent>
                        </Collapse>
                    </Card>
                )) :
                    <Container>
                        {temp.map((val, index) => {
                            return (
                                <Card className={classes.root}>
                                    <CardHeader
                                        avatar={
                                            <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                        }
                                        title={
                                            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                                        }
                                    />
                                </Card>)
                        })

                        }
                    </Container>
                }
            </Container>
        </Layout>
    );
}
